<template>
  <table class="table">
    <tr
      v-for="(item, ind) in tableData"
      :key="ind"
      class="item"
      @click="$emit('row_click', { id: item.id, item: item })"
    >
      <slot :name="'item.' + ind" v-bind:item="item">
        <td
          style="white-space: pre-line; width: 50%"
          v-for="(value, key, index) in item"
          :key="index"
          :style="key === 'value' ? 'text-align: left' : ''"
        >
          <slot
            :name="'item.' + ind + '.' + key"
            v-bind:item="item"
            v-bind:keyName="key"
            v-bind:value="value"
          >
            {{ value }}
          </slot>
        </td>
      </slot>
    </tr>
  </table>
</template>

<script>
export default {
  name: "BasicTableWithoutHeader",
  props: {
    tableData: {
      type: Array,
      required: true
    }
  }
};
</script>

<style scoped></style>
